$fonts: (
    heading: #{"Big Shoulders Text", cursive},
    subtitle: #{"EB Garamond", serif},
    other: #{"Poppins", sans-serif}
);

$colors: (
    bg: #273361,
    main: #FFD866,
    button: #42AD86,
    danger: #dc3545
);

@function color($color-name) {
    @return map-get($colors, $color-name)
}

@function font($font-name) {
    @return map-get($fonts, $font-name)
}
@mixin mobile {
    @media (max-width: 799px) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: 800px) {
        @content;
    }
}

html, body, #top, #items {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

#bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: color(bg);
    z-index: -1;

    @include desktop {
        clip-path: polygon(0 0, 60% 0, 45% 100%, 0% 100%);
    }
}

#items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @include desktop {
        height: 100%;
        width: 100%;
        display: grid;
        justify-items: start;
        grid-template-columns: 5vw 40vw 15vw 40vw;
        grid-template-rows: 100%;
        text-align: left;
    }

    #up {

        * {
            margin: 0;
            padding: 0;
        }

        @include desktop {
            grid-column-start: 2;
            grid-column-end: 3;
        }

        h1 {
            color: color(main);
            font-family: font(heading);
            font-size: 10vh;
            margin: 0 10px; 

            @include desktop {
                margin: 0;
            }
        }

        .subtitle {
            color: color(button);
            font-family: font(subtitle); 
            font-size: 3vh;
        }
    }

    .hidden {
        display: none !important;
    } 
}

nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0 !important;

    @include desktop {
        align-items: flex-start;
    }

    button {
        margin: 5px !important; 
        height: 5vh;
        border-radius: 2vh;
        width: 70vw;
        border: none;
        text-decoration: none;
        font-size: 4vh;
        font-family: font(heading);
        text-transform: uppercase;
        background-color: color(button);
        color: white;

        @include mobile {
            &.selected {
                background-color: color(main);
            }
        }

        @include desktop {
            width: 5em;
            text-align: left;
            background-color: color(bg);
            border: none;
            text-decoration: underline;
            &.selected {
                color:color(main);
            }
        }
    }
}

.card {
    background-color: white;
    border-radius: 2vh;
    padding: 10px !important;
    font-size: 2vh;
    font-family: font(other);
    width: 80vw;
    box-shadow: 3px 3px 10px 1px rgba(23,23,23,1);

    @include desktop {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        grid-column-start: 4;
        grid-column-end: 5;
        width: 30vw;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    .form-group {
        padding: 5px !important;

        @include desktop {
            margin: 10px;
        }

        input[type=text] {
            border: none;
            border-bottom: 1px solid black;
            font-size: 2vh;
            font-family: font(other);

            @include desktop {
                width: 20vw;
            }
        }

        input:focus {
            outline: 0;
        }

    }

    .error {
        color: color(danger);
    }

    button {
        height: 3.5vh;
        border: none;
        text-decoration: none;
        font-size: 3vh;
        font-family: font(other);
        margin: 0 auto;
        padding: 5px;
        color:color(button);

        @include desktop {
            width: 20vw;
            margin: 0 auto;
            padding: 0 0.5em;
            border-radius: 15px;
        }
    }

    .rules {
        font-size: 2vh;

        @include desktop {
            text-align: center;
        }
    }

    .radio {
        display: block
    }

    .uppercase {
        text-transform:uppercase;
    }
}























